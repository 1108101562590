import React, { useEffect, useState, useCallback, useMemo } from "react";
import CategoriaService from "features/categoria/categoriaService";
import PastoService from "services/PastoService";
import pendencyService from "features/pendency/pendencyService";
import { InputAdornment, withStyles } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { CircularProgress } from "@material-ui/core";
import { ajustaDataComTimezoneFazenda, formatDateHour } from "shared/utils";
import dayjs from "dayjs";
import { utc } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import CustomTextField from "features/shared/components/CustomTextField";
import PendencyEditButtons from "../../pendencyEditView/components/pendencyEditButtons";
import { connect } from "react-redux";
import SelectDropdown from "components/SelectDropdown";
import * as actions from "redux/actions";
import { getFarmTimezone } from "features/collect/supplementation/supplementationUtils";
import NumberField from "features/shared/components/NumberField";
import MovementsHistory from "./movementsHistory";
import { TotalHerd } from "./totalHerd";
import { validateData } from "./pendencyDetailsUtils";
import { TIPO_MOVIMENTACAO } from "constants/tipoColetas";
import { DateTimePicker } from "material-ui-pickers";
import CalendarIcon from "@material-ui/icons/Today";
import {
  CONTENT_TOOLTIP,
  LATE_SYNC_STYLE,
  VERIFICATION_MESSAGE,
} from "features/pendency/common/constants";
import { renderPendencyCanBeAppliedMessage } from "features/pendency/common/utils/renderPendencyCanBeAppliedMessage";
import { checkDate } from "features/pendency/common/utils/checkDate";
import { generateSelectOptions } from "features/pendency/common/utils/generateSelectOptions";

dayjs.extend(utc);
dayjs.extend(timezone);

const PendencyDetailsCard = ({
  classes,
  pendency,
  isEditPage,
  editable,
  pageType,
  onGoBack,
  history,
  carregando,
  notifyError,
  notifySuccess,
}) => {
  const timezoneFazenda = getFarmTimezone();
  const [originRetreatName, setOriginRetreatName] = useState("");
  const [originPastureName, setOriginPastureName] = useState("");
  const [reasonPendency, setReasonPendency] = useState("");
  const [isLateSync, setIsLateSync] = useState(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [verificationMessage, setVerificationMessage] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [pastureOptions, setPastureOptions] = useState([]);
  const [selectOriginCategory, setSelectOriginCategory] = useState("");
  const [selectDestinyCategory, setSelectDestinyCategory] = useState("");
  const [selectOriginPasture, setSelectOriginPasture] = useState("");
  const [selectDestinyPasture, setSelectDestinyPasture] = useState("");
  const [number, setNumber] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const [inputs, setInputs] = useState({
    creationUser: "",
    movementType: "",
    totalHerd: "",
    movementTypeId: null,
    createdAt: "",
    farmId: "",
  });
  const [operationDate, setOperationDate] = useState("");
  const [dateInTheFuture, setDateInTheFuture] = useState("");

  const handleChange = (value) => {
    const now = new Date().getTime();
    const newDateValue = new Date(value).getTime();
    const formattedDate = value.format("YYYY-MM-DDTHH:mm:ss.SSS");

    const message =
      newDateValue > now ? "Não é possível selecionar uma data no futuro" : "";

    setDateInTheFuture(message);
    setOperationDate(formattedDate);
  };

  const adjustedDateToChecks = useMemo(
    () => ajustaDataComTimezoneFazenda(operationDate, timezoneFazenda),
    [operationDate, timezoneFazenda]
  );

  const pastureOriginSelected = useMemo(
    () =>
      pastureOptions.find((pasture) => pasture.IdPasto === selectOriginPasture),
    [pastureOptions, selectOriginPasture]
  );
  const categoryOriginSelected = useMemo(
    () =>
      categoryOptions.find(
        (category) => category.IdCategoriaAnimal === selectOriginCategory
      ),
    [categoryOptions, selectOriginCategory]
  );
  useEffect(() => {
    const { MOVEMENT_BETWEEN_PASTURES, CATEGORY_CHANGE } = TIPO_MOVIMENTACAO;
    if (pendency) {
      if (pendency.movementTypeId === MOVEMENT_BETWEEN_PASTURES) {
        setSelectDestinyCategory(selectOriginCategory);
      }
      if (pendency.movementTypeId === CATEGORY_CHANGE) {
        setSelectDestinyPasture(selectOriginPasture);
      }
    }
  }, [selectOriginPasture, selectOriginCategory]);

  const handleChangeOriginCategory = (event) => {
    setSelectOriginCategory(event);
  };
  const handleChangeDestinyCategory = (event) => {
    setSelectDestinyCategory(event);
  };
  const handleChangeOriginPasture = (event) => {
    setSelectOriginPasture(event);
  };
  const handleChangeDestinyPasture = (event) => {
    setSelectDestinyPasture(event);
  };

  const handleChangeNumber = (event) => {
    const { value } = event.target;
    if (value) {
      setNumber(value);
    }
  };
  const fetchCategoriesAndPastures = async (farmId) => {
    try {
      const categoriesList = await CategoriaService.listaCategoriasPorFazenda(
        farmId
      );
      setCategoryOptions(categoriesList.data);
      const pasturesList = await PastoService.listaPastosPorIdFazenda(farmId);
      setPastureOptions(pasturesList.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (pendency.movementTypeId === 13 && isEditPage) {
      return history.goBack();
    }
    if (pendency && Object.keys(pendency).length > 0) {
      setInputs({
        creationUser: pendency.creationUser,
        movementType: pendency.movementType,
        totalHerd: null,
        operationDate: formatDateHour(dayjs.utc(pendency.operationDate)),
        movementTypeId: pendency.movementTypeId,
        createdAt: formatDateHour(dayjs.utc(pendency.createdAt)),
        farmId: pendency.farmId,
      });
      setNumber(pendency.number);
      setSelectOriginCategory(
        pendency.origin.categoryId || pendency.destiny.categoryId
      );
      setSelectOriginPasture(pendency.origin.pastureId);
      setOriginRetreatName(pendency.origin.retreat);
      setOriginPastureName(pendency.origin.pasture);
      setReasonPendency(pendency.pendencyReason);
      fetchCategoriesAndPastures(pendency.farmId);
      setOperationDate(pendency.operationDate.replace("Z", ""));
      if (pendency.destiny) {
        setSelectDestinyPasture(pendency.destiny.pastureId);
        setSelectDestinyCategory(pendency.destiny.categoryId);
      } else {
        setSelectDestinyPasture(null);
        setSelectDestinyCategory(null);
      }
    }
  }, [pendency]);

  useEffect(() => {
    if (pendency.createdAt && pendency.operationDate) {
      const operationAndSyncTimeDiference = dayjs(pendency.createdAt).diff(
        pendency.operationDate,
        "hours"
      );
      const twelveHours = 12;
      const isLate = operationAndSyncTimeDiference >= twelveHours;
      setIsLateSync(isLate);
    }
  }, [pendency]);

  const fetchTotalHerd = useCallback(async () => {
    if (
      dayjs(adjustedDateToChecks).isValid() &&
      selectOriginCategory !== null &&
      selectOriginPasture !== null
    ) {
      const dateTocheck = adjustedDateToChecks.toISOString();
      const resultOriginCount =
        await pendencyService.getCountAnimalCategoryInPasture(
          selectOriginCategory,
          selectOriginPasture,
          dateTocheck
        );
      setInputs({ totalHerd: resultOriginCount });
    }
  }, [selectOriginCategory, selectOriginPasture, adjustedDateToChecks]);

  useEffect(() => {
    fetchTotalHerd();
  }, [fetchTotalHerd]);

  const isCategoryChange =
    pendency.movementTypeId === TIPO_MOVIMENTACAO.CATEGORY_CHANGE;
  const isMovementBetweenPastures =
    pendency.movementTypeId === TIPO_MOVIMENTACAO.MOVEMENT_BETWEEN_PASTURES;

  const categoryOriginOptionList = generateSelectOptions(
    categoryOptions,
    isCategoryChange ? pendency.destiny.categoryId : null
  );

  const categoryDestinyOptionList = generateSelectOptions(categoryOptions);

  const pastureDestinyOptionList = generateSelectOptions(
    pastureOptions,
    null,
    "Nome",
    "IdPasto"
  );

  const pastureOriginOptionList = generateSelectOptions(
    pastureOptions,
    isMovementBetweenPastures ? pendency.destiny.pastureId : null,
    "Nome",
    "IdPasto"
  );

  const applyPendency = async (movementToApply) => {
    try {
      carregando(true);
      const pendencyResponse = await pendencyService.applyPendency(
        movementToApply
      );
      notifySuccess({
        mensagem: "A pendência foi resolvida com sucesso!",
      });
      return pendencyResponse;
    } catch (error) {
      notifyError({
        mensagem: "Ocorreu um erro ao resolver a pendência.",
      });
    } finally {
      carregando(false);
    }
  };

  const handleSubmit = useCallback(async () => {
    const movementFields = {
      creationUser: pendency.creationUser,
      movementType: pendency.movementType,
      pastureOrigin: selectOriginPasture,
      pastureDestiny: selectDestinyPasture,
      animalCategoryOrigin: selectOriginCategory,
      animalCategoryDestiny: selectDestinyCategory,
      number: +number,
      operationDate: adjustedDateToChecks.toISOString(),
      movementTypeId: pendency.movementTypeId,
      createdAt: pendency.createdAt,
      farmId: pendency.farmId,
      movementGrouper: pendency.movementGrouper,
    };

    const result = await applyPendency(movementFields);

    if (result && (result.statusText === "OK" || result.status === 200)) {
      history.replace("/movimentacoesPendentes");
    }
  }, [
    selectOriginPasture,
    selectDestinyPasture,
    selectOriginCategory,
    selectDestinyCategory,
    number,
    adjustedDateToChecks,
  ]);

  useEffect(() => {
    validateData(
      pendency.movementTypeId,
      selectOriginCategory,
      selectDestinyCategory,
      selectOriginPasture,
      selectDestinyPasture,
      parseInt(number),
      adjustedDateToChecks,
      setShowLoading,
      setIsSaveEnabled,
      setVerificationMessage,
      VERIFICATION_MESSAGE,
      dateInTheFuture
    );
  }, [
    number,
    selectOriginCategory,
    selectDestinyCategory,
    selectOriginPasture,
    selectDestinyPasture,
    adjustedDateToChecks,
  ]);

  return (
    <div className={classes.detailsWrapper}>
      <div className={classes.titlePendencyDetailContainer}>
        <h1
          data-testid="TestTitleMovementDetail"
          className={classes.titlePendencyDetailText}
        >
          Detalhes da movimentação
        </h1>
        {isEditPage && (
          <p className={classes.description}>
            Ajuste os dados da movimentação abaixo, para que seja corrigida e
            criada uma nova movimentação válida.
          </p>
        )}
      </div>

      <div className={classes.titlePendencyHistoryContainer}>
        <h1 className={classes.titlePendencyDetailText}>
          Histórico de Movimentações
        </h1>
        <p className={classes.description}>
          Visualize ao vivo a situação do pasto e da categoria animal de acordo
          com as modificações realizadas.
        </p>
      </div>
      <div
        className={classes.pendencyCardContainer}
        data-testid="PendencyDetailsCard"
      >
        <div className={classes.cardContainerContent}>
          {showLoading && (
            <div className={classes.verificationContainer}>
              <CircularProgress className={classes.progress} />
            </div>
          )}
          <div className={classes.cardContainer}>
            <div className={classes.retiroAndPastureContainer}>
              <div className={classes.retiroText} data-testid="retreatName">
                Retiro: {originRetreatName}
              </div>
              <div className={classes.divider} />
              <div className={classes.pastureText} data-testid="pastureName">
                Pasto: {originPastureName}
              </div>
            </div>
            <div className={classes.explanationPendencyContainer}>
              {verificationMessage.length > 0 &&
                renderPendencyCanBeAppliedMessage(verificationMessage, classes)}
              {!verificationMessage &&
                renderPendencyCanBeAppliedMessage(reasonPendency, classes)}
            </div>
            <div className={classes.content}>
              <div className={classes.fieldLine}>
                <CustomTextField
                  classes={classes}
                  testId={`creationUser`}
                  id="creationUser"
                  label="Usuário"
                  value={inputs.creationUser}
                  flex={2}
                  disabled
                />
                <CustomTextField
                  classes={classes}
                  testId={`movementType`}
                  id="movementType"
                  label="Tipo de operação"
                  value={inputs.movementType}
                  disabled
                />
              </div>

              <div className={classes.fieldLine}>
                <NumberField
                  className={classes.totalMoved}
                  data-testid={"numberTotalMoved"}
                  id="numberTotalMoved"
                  label="Total movimentado"
                  name={"number"}
                  value={number}
                  disabled={!editable}
                  onChange={handleChangeNumber}
                  flex={2}
                  inputProps={{
                    style: {
                      paddingBottom: "12px",
                    },
                  }}
                />
                <TotalHerd
                  total={inputs.totalHerd}
                  pasture={pastureOriginSelected}
                  category={categoryOriginSelected}
                  operationDate={operationDate}
                  customStyles={{
                    alignSelf: "end",
                    flex: 2,
                  }}
                />
              </div>
              <div className={classes.fieldLine}>
                <SelectDropdown
                  classes={classes}
                  testId="originCategoryName"
                  id="originCategoryName"
                  label={{
                    text: "Categoria inicial",
                  }}
                  options={categoryOriginOptionList}
                  value={selectOriginCategory}
                  flex={2}
                  isDisabled={!editable}
                  onChange={handleChangeOriginCategory}
                  variant="standard"
                />
                <SelectDropdown
                  classes={classes}
                  testId="destinyCategoryName"
                  id="destinyCategoryName"
                  label={{ text: "Categoria final" }}
                  name={"animalCategoryDestinyName"}
                  options={categoryDestinyOptionList}
                  value={selectDestinyCategory}
                  flex={2}
                  isDisabled={true}
                  onChange={handleChangeDestinyCategory}
                  variant="standard"
                />
              </div>
              <div className={classes.fieldLine}>
                <SelectDropdown
                  classes={classes}
                  testId="originPastureName"
                  id="originPastureName"
                  label={{ text: "Pasto origem" }}
                  options={pastureOriginOptionList}
                  name={"pastureOriginName"}
                  value={selectOriginPasture}
                  flex={2}
                  isDisabled={!editable}
                  onChange={handleChangeOriginPasture}
                  variant="standard"
                />
                <SelectDropdown
                  classes={classes}
                  testId="destinyPastureName"
                  id="destinyPastureName"
                  label={{ text: "Pasto destino" }}
                  options={pastureDestinyOptionList}
                  name={"pastureDestinyName"}
                  value={selectDestinyPasture}
                  isDisabled={true}
                  onChange={handleChangeDestinyPasture}
                  variant="standard"
                />
              </div>
              <div className={classes.fieldLine}>
                <div className={classes.fieldFlex} style={{ flex: 2 }}>
                  <DateTimePicker
                    testId="operationDate"
                    fullWidth
                    okLabel={"Selecionar"}
                    cancelLabel={"Cancelar"}
                    data-testid={`operationDate`}
                    InputLabelProps={{ shrink: true }}
                    ampm={false}
                    disableFuture
                    disabled={!editable}
                    className={classes.datePickerField}
                    label="Data/hora da coleta"
                    value={checkDate(operationDate)}
                    onChange={handleChange}
                    maxDate={new Date()}
                    format="DD/MM/YYYY HH:mm"
                    maxDateMessage="Não é possível selecionar uma data no futuro."
                    invalidDateMessage="Data Inválida"
                    helperText={dateInTheFuture}
                    error={dateInTheFuture}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CalendarIcon />
                        </InputAdornment>
                      ),
                      style: {
                        marginTop: "21px",
                      },
                    }}
                  />
                </div>

                <CustomTextField
                  classes={classes}
                  testId={`createdAt`}
                  id="createdAt"
                  label={
                    <div
                      className={classes.labelContent}
                      style={{ padding: `${isLateSync ? "4px" : "unset"}` }}
                    >
                      {isLateSync && (
                        <Tooltip
                          data-testid="testLateSyncTooltip"
                          title={CONTENT_TOOLTIP}
                          className={classes.tooltip}
                          classes={{
                            tooltip: classes.tooltipContent,
                          }}
                        >
                          <InfoIcon />
                        </Tooltip>
                      )}
                      Data/hora da sincronização
                    </div>
                  }
                  value={inputs.createdAt}
                  disabled
                  style={isLateSync ? LATE_SYNC_STYLE : undefined}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.historyCardContainer}>
        <MovementsHistory
          pasture={pastureOriginSelected}
          category={categoryOriginSelected}
          baseDate={operationDate}
          numberPendency={number}
          totalHerd={inputs.totalHerd}
          timezoneFazenda={timezoneFazenda}
          showLoading={showLoading}
        />
      </div>

      {pageType === "edit" && (
        <div className={classes.actionsContainer}>
          <PendencyEditButtons
            classes={classes}
            history={history}
            onGoBack={onGoBack}
            onSubmit={handleSubmit}
            isSaveEnabled={isSaveEnabled}
          />
        </div>
      )}
    </div>
  );
};

const styles = (theme) => ({
  detailsWrapper: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    gridTemplateRows: "auto 1fr auto",
    rowGap: "6px",
    columnGap: "18px",
    height: "100%",
    marginTop: "40px",
    marginBottom: "40px",
  },
  titlePendencyDetailContainer: {
    gridRowStart: "1",
    gridColumnStart: "1",
    gridRowEnd: "2",
    gridColumnEnd: "4",
  },
  titlePendencyHistoryContainer: {
    gridRowStart: "1",
    gridColumnStart: "4",
    gridRowEnd: "2",
    gridColumnEnd: "6",
  },
  pendencyCardContainer: {
    // position: "relative",
    gridRowStart: "2",
    gridColumnStart: "1",
    gridRowEnd: "3",
    gridColumnEnd: "4",
  },
  historyCardContainer: {
    gridRowStart: "2",
    gridColumnStart: "4",
    gridRowEnd: "3",
    gridColumnEnd: "6",
  },
  actionsContainer: {
    gridRowStart: "3",
    gridColumnStart: "1",
    gridRowEnd: "4",
    gridColumnEnd: "6",
  },
  cardContainerContent: {
    position: "relative",
  },
  verificationContainer: {
    borderRadius: "8px",
    zIndex: 20,
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: 0,
    left: 0,
    backgroundColor: "rgba(255,255,255,0.49)",
    width: "100%",
    height: "100%",
  },
  progress: {
    color: theme.palette.primary.main,
  },
  cardContainer: {
    borderRadius: "8px",
    boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.2)",
    border: "1px solid #e5e5ea",
    backgroundColor: "#fff",
    position: "relative",
  },
  retiroAndPastureContainer: {
    padding: "9px",
    margin: "32px",
    borderRadius: "8px",
    backgroundColor: "#F8F8F8",
    display: "flex",
    justifyContent: "flex-start",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "700",
    letterSpacing: "0.5px",
    color: "#444",
    textTransform: "uppercase",
    alignItems: "center",
  },
  divider: {
    width: "1px",
    height: "9px",
    backgroundColor: "#E2E2E2",
    margin: "5px 15px",
  },
  retiroText: {
    paddingLeft: "16px",
  },
  pastureText: {
    paddingLeft: "3px",
  },
  explanationPendencyContainer: {
    fontFamily: "Roboto",
    margin: "16px 32px",
    fontSize: "12px",
    fontStyle: "italic",
  },
  content: {
    margin: "32px",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  fieldLine: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    gap: "32px",
  },

  titlePendencyDetailText: {
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: "bold",
    color: "#444",
  },
  labelContent: {
    display: "flex",
    alignItems: "center",
  },
  tooltip: {
    fontSize: "14.5px",
    color: "#FFC944",
    marginRight: "6px",
  },
  tooltipContent: {
    fontSize: "12px",
  },
  totalMoved: {
    width: "48.5%",
  },
  description: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.default1.light,
  },
  verificationMessage: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    color: "#32A07D",
  },
  successIcon: {
    color: "#32A07D",
    fontSize: "18px",
  },
  lateSync: {},
});

const mapDispatchToProps = {
  carregando: actions.exibirCarregando,
  notifyError: actions.notifyError,
  notifySuccess: actions.notifySuccess,
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(PendencyDetailsCard));
